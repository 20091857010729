<template>
    <div class="blocker flex">
      <img  @click="close" class="close-icon" src="@/assets/images/icons/ic_close.svg" alt="close">
      <div class="center-section">
        <img class="icon" src="@/assets/images/icons/ic_rotate.svg" alt="rotate">
        <div class="text-section">
          <strong class="fs-18">
            Please rotate your screen
          </strong>
          <div class="text-sentences">
            You will need to take photos in landscape mode or turn off your orientation lock.
          </div>
          <div class="fs-18 mt-5" @click="closeAnyWay">
            <b><u>CONTINUE ANYWAY</u></b>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
export default {
  name: 'BlockScreen',
  props: {
    close: { type: Function },
    closeAnyWay: { type: Function },
  }
}
</script>
<style lang="scss">
.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.blocker {
  position: absolute;
  z-index: 2000;
  background: #565656;
  height: 100%;
  width: 100%;
  color: white;

  .close-icon {
    position: fixed;
    top: 37px;
    right: 33px;
    height: 31px;
    width: 31px;
  }

  .center-section {
    .icon {
      margin: 0 auto;
      height: 75px;
      width: 75px;
      display: block;
    }
    
    .text-section {
      margin: 26px 44px 0;
      text-align: center;
      max-width: 340px;
      max-height: 202px;

      .text-sentences {
        margin-top: 30px;
        font-size: 18px;
        line-height: 27px;
      }
    }
  }
}
</style>